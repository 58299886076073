@import './../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './../../node_modules/datatables/media/css/jquery.dataTables.min.css';
@import './../../node_modules/chart.js/dist/Chart.min.css';

.base {
  margin-top: 100px;
}

.container-fluid {
  padding: 0 50px;
}

@media only screen and (max-width: 1240px) {
  .container-fluid {
    padding: 0 5px;
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

